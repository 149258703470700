import { Component, ContentChild, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CellClickEvent, FilterableSettings, GridComponent, GridItem, GroupableSettings, GroupBindingDirective, GroupRowArgs, ScrollMode, SelectableSettings, SortSettings } from '@progress/kendo-angular-grid';
import { TooltipDirective } from "@progress/kendo-angular-tooltip";
import { AggregateDescriptor as KendoAggregateDescriptor, CompositeFilterDescriptor, GroupDescriptor as KendoGroupDescriptor, process, SortDescriptor, DataResult } from '@progress/kendo-data-query';
import { merge, Subscription } from 'rxjs';
import { GridColumnDef } from '../models/grid-column-def';
import { DEFAULT_EXCEL_OPTIONS, DEFAULT_PDF_OPTIONS, GRID_ROW_NUMBER_FIELD } from '../extended-grid/constants/default-values';
import { exportPDF as KendoExportAsPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { GridHeaderActionsDirective } from '../../../directives/grid-header-actions.directive';
import { CellDoubleClickEvent, ExcelOptions, GridTemplatesDirective, PDFOptions, RowArgs, RowSelectedFn } from '../extended-grid';
import { RowClassCallback } from '../grid/model';
import { groupBy } from '../../../utils/data-query';
import { numberFormatter } from '../utils';
import { EXPORT_ERROR_TITLE, PDF_EXPORT_DISABLED_CONTENT } from '../extended-grid/constants/messages';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DialogService } from '../../../services/dialog.service';

 interface GridValidationResult {
  valid: boolean;
  errors: { [key: string]: any }[]
}

 interface ValueChangeEvent {
  validation: GridValidationResult
}

interface GroupDescriptor extends KendoGroupDescriptor {}

interface AggregateDescriptor extends KendoAggregateDescriptor {}
@Component({
  selector: 'williams-ui-platform-click-to-editable',
  templateUrl: './click-to-editable.component.html',
  styleUrls: ['./click-to-editable.component.scss']
})
export class ClickToEditableGridComponent implements OnChanges {
  private counter = 0;
  public expandedKeys: string[] = [];
  private toggleGrpMap = new Map<any,any>;
  @ViewChild('grid', { static: false }) grid!: GridComponent;
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;
  @ContentChild(forwardRef(()=>GridHeaderActionsDirective)) gridHeaderAction!: any;
  @ViewChild("groupDirective")groupDirective!: GroupBindingDirective;
  @Input('gridData') set gridData(data: any[]) {
    this._gridData = data.map(value => ({
      ...value,
      rowId: this.counter++,
      isNewRecord: false
    }));
    this.rowsInEditMode = new Map();
  };
  @Input('gridColumnDefs') set columnDefs(data: GridColumnDef[]) {
    this.gridColumnDefs = data.map(item => {
      return {
        ...item,
        editConfig: item.editConfig ?? {
          add: item.editable,
          edit: item.editable
        },
        formControlName: item.formControlName ?? item.field
      }
    })
  };
  @Input() createFormGroupFunction: any;
  @Input() height!: number;
  @Input() showtableTitle = false;
  @Input() tableTitle: string = '';
  @Input() showBulkDelete = true;
  @Input() showBulkDuplicate = true;
  @Input() enableRowAddition = true;
  @Input() enableSelection = true;
  @Input() showCommandColumn = true;
  @Input() filterable: FilterableSettings = 'menu';
  @Input() sortable: SortSettings = false
  @Input() resizable = true;
  @Input() autoAdjustPdfWidth = false;
  @Input() showAggregate = true;
  @Input() hideCheckBoxColumnConfig = false;
  @Input() rowCountLable: string = 'row';
  @Input() hightlightRowsWithError = false;
  @Input("pdfOptions") set _pdfOptions(options: Partial<PDFOptions>) {
    this.pdfOptions = {
      ...DEFAULT_PDF_OPTIONS,
      ...options,
    };
  }
  @Input("excelOptions") set _excelOptions(options: Partial<ExcelOptions>) {
    this.excelOptions = {
      ...DEFAULT_EXCEL_OPTIONS,
      ...options,
    };
  }
    // Whether to show count of rows at the grid footer
  pdfOptions: PDFOptions = DEFAULT_PDF_OPTIONS;
  excelOptions: ExcelOptions = DEFAULT_EXCEL_OPTIONS;
  @Input() rowClassCallbackFn!: RowClassCallback;
  @Input() columnClassCallbackFn!: (dataItem: any, field: string) => string | string[] | { [key: string]: any } | Set<string>;
  @Input() addCallbackFn!: Function;
  @Input() emptyRecordsMessage = 'No records available.'
  @Input() pageSize = 100;
  @Input() reorderable = true;
  // Inputs related to Grid Grouping
  @Input() groupable: GroupableSettings | boolean = false;
  @Input() groups: GroupDescriptor[] = [];
  @Input() groupAggregates: AggregateDescriptor[] = [];
  @Input() readonly = false;
  @Input() scrollable: ScrollMode = "virtual";
  @Input() rowSelectedFn: RowSelectedFn = (rowArgs: RowArgs) => {
    return this.selectedRows.includes(rowArgs.dataItem.rowId);
  };

  @Input() rowHeight = 28;
  @Output() valueChange = new EventEmitter<ValueChangeEvent>();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() dataStateChange = new EventEmitter<ValueChangeEvent>(); // To notify component that filter,sort,grouping has been applied
  @Output() cellClick = new EventEmitter<CellClickEvent>();
  @Output() cellDoubleClick = new EventEmitter<CellDoubleClickEvent>();
  @ContentChild(forwardRef(() => GridTemplatesDirective))
  gridTemplates!: GridTemplatesDirective;
  gridColumnDefs: GridColumnDef[] = [];
  _gridData!: any[];
  @Input() selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
    mode: 'multiple'
  }
  rowTotal:number = 0;

  splitButtonData = [{
    text: 'Add 5 items',
    click: () => {
      this.addRows(5);
    }
  }, {
    text: 'Add 10 items',
    click: () => {
      this.addRows(10);
    }
  }]

  gridRowCommands = [{
    text: 'Copy',
    action: 'duplicate'
  }];

  selectedRows: number[] = [];

  gridView: any[] = [];
  @Input('filter') filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  @Input() sort: SortDescriptor[] = []

  rowsInEditMode: Map<number, FormGroup> = new Map();
  private _subscription!: Subscription;


  constructor(
    private _dialogService: DialogService
  ) {
    this.rowClassCallback = this.rowClassCallback.bind(this);
    this.allDataForExcelExport = this.allDataForExcelExport.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['gridData']) {
      this.loadGridView();
      this.createToggleGroupMap(this._gridData);
      if(!changes['gridData'].isFirstChange()) {
         this.expandedKeys =[];
         this.resetGrid();
       }
    }
  }

  onCellClick(event: CellClickEvent) {
    const { dataItem, rowIndex } = event;
    if(!this.readonly) {
      this._editGridRow(dataItem, rowIndex);
    }
    this.cellClick.emit(event);
  }

  onCellDoubleClick(
    dataItem: any,
    column: GridColumnDef,
    rowIndex: number
  ) {
    this.cellDoubleClick.emit({ dataItem, column, rowIndex });
  }

  getDataInSequenceAfterProcess() {
    const expandedItems = this.getExpandedItems();
    let data = process(this._gridData, { sort: this.sort, group: this.groups }).data;
    data = this.flattenGridDataArray(data);
    const updatedData = data.map((item: any,index: number) => {
      return {
        ...item,
        ...(this.rowsInEditMode.get(item.rowId)?.getRawValue() ?? {}),
        index: index,
        currentStateIndex: expandedItems.findIndex(record => record.rowId == item.rowId)
      };
    });
    return updatedData;
  }

  private _editGridRow(dataItem: any, rowIndex: number) {
    const group = this.rowsInEditMode.get(dataItem.rowId);
    if(group) {
      this.grid.editRow(rowIndex, group);
    }
    else {
      const group = this.createFormGroup(dataItem);
      this.rowsInEditMode.set(dataItem.rowId, group);
      this.grid.editRow(rowIndex, group);
    }
  }

  public groupKey = (groupRow: any):string |any => {
    if (!groupRow) {
      return null;
    }
    return [this.groupKey(groupRow.parentGroup), groupRow?.group?.value]
      .filter((id) => id !== null)
      .join('#');
  };

  loadGridView() {
    this.groups = this.groups.map(group => ({ ...group, aggregates: this.groupAggregates }));
    this.filter = this.filter;
    this.sort = this.sort;
    const processedData = process(this._gridData, { sort: this.sort, filter: this.filter, group: this.groups });
    this.rowTotal = processedData.total;
    this.gridView = this._flattenGroupedGridViewData(processedData.data);
  }

  handleFormValueChange(): any {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    const groups = this._gridData.filter(item => this.rowsInEditMode.get(item.rowId) as FormGroup);
    this._subscription = merge(...groups.map(item => item.valueChanges)).subscribe(() => {
      const payload = {
        validation: this.validate(groups)
      }
      this.valueChange.emit(payload);
    });
  }

  createToggleGroupMap(data: any[]): void {
    const processedData = this.getDataAfterProcess(data);
    this.toggleGrpMap = this.traverseForToggleMap(processedData.data);
  }

  traverseForToggleMap(data: any): Map<any,any> {
    const pathMap = new Map();
    const traverse = (node: any, currentPath: any) => {
      node.items &&
        node.items.length > 0 &&
        pathMap.set(currentPath, {
          node,
          displayVal: node.value,
          expanded: true
        });

      if (node.items && Array.isArray(node.items)) {
        // Iterate over each item in the items array
        node.items.forEach((child: any, index: any) => {
          // Construct the new path
          const newPath = currentPath ? `${currentPath}_${index}` : `${index}`;
          // Recursively traverse the child node
          traverse(child, newPath);
        });
      }
    };
    data.forEach((node: any, index: any) => {
      traverse(node, `${index}`);
    });

    return pathMap;
  }

  manualGridSort(event: SortDescriptor[],gridData?:any) {
    const manualSort = event;
    const group = this.groups.map(group => ({ ...group, aggregates: this.groupAggregates }))
    let processedData: any;
    if(gridData){
      processedData = process(gridData, { sort: manualSort, filter: this.filter, group });
    }else{
      processedData = process(this._gridData, { sort: manualSort, filter: this.filter, group });
    }
    this.rowTotal = processedData.total;
    const falttenData = this._flattenGroupedGridViewData(processedData.data);
    this.gridView = falttenData;
    this.expandAllGroupsInToggleMap();
    setTimeout(() => this.editRecordOnToggle(),0);
    return falttenData;
  }

  getGridDataList(): any[] {
    return this.gridView;
  }

  flattenGridDataArray(group: any[]): any[] {
    const output = [];
    for(let groupItem of group) {
      if(groupItem['field']) {
        output.push(...this.flattenGridDataArray(groupItem.items));
      } else {
        output.push(groupItem);
      }
    }
    return output;
  }
  
  private closeAllRows(): void {
    const data = this.getGridDataList();
    data.forEach((dataItem, index) => {
        this.grid.closeRow(index);
    })
  }

  private resetGrid(): void {
    this.closeAllRows();
    this.rowsInEditMode.clear();
  }

  getDataAfterProcess(gridData: any[]): DataResult {
    const data = process(gridData, { sort: this.sort, filter: this.filter, group: this.groups })
    return data;
  }
  
  collapseAll() {
    this.collapseAllGroupsInToggleMap();
    setTimeout(() => this.groupDirective.collapseAll(),0);
  }

  applyFilter(filter: CompositeFilterDescriptor): void {
    const processedData = process(this._gridData, {
      group: this.groups,
      filter: filter,
      sort: this.sort,
    });
    this.gridView = this._flattenGroupedGridViewData(processedData.data);
  }

  editAllRows() {
    if(this.readonly) {
      return;
    }
    const data = this.getGridDataList();
    data.forEach((dataItem, index) => {
      this.grid.closeRow(index);
      const group = this.rowsInEditMode.get(dataItem.rowId);
      if (group) {
        this.grid.editRow(index, group);
      } else {
        const group = this.createFormGroupFunction(dataItem) as FormGroup;
        this.rowsInEditMode.set(dataItem.rowId, group);
        this.grid.editRow(index, group);
      }
    });
    this.handleFormValueChange();
  }

  addRows(count: number = 1) {
    const items = Array(count).fill({}).map(() => ({ ...this.addCallbackFn(), rowId: this.counter++, isNewRecord: true }));
    this._gridData.unshift(...items);
    this.loadGridView();
    this.editAllRows();
  }

  onCommandItemClick({ action }: { action: 'duplicate' }, dataItem: any): void {
    switch (action) {
      case 'duplicate': this.duplicateRow(dataItem);
        break;
    }
  }

  private get _isGridInEditMode(): boolean {
    return !!this.grid && this.grid.isEditing();
  }

  get isGridEditing(): boolean {
    return this._isGridInEditMode 
  }

  duplicateRow(dataItem: any) {
    const item = this.mergeEditedDataWithOriginalData(
      dataItem.rowId,
      this.rowsInEditMode.get(dataItem.rowId)?.getRawValue()
    );
    this._gridData.unshift({ ...item, rowId: this.counter++, isNewRecord: true });
    this.loadGridView();
    this.editAllRows();
  }

  deleteRow(dataItem: any,event: Event) {
    event?.stopPropagation();
    const index = this._gridData.findIndex(item => item.rowId === dataItem.rowId);
    this._gridData.splice(index, 1);
    this.loadGridView();
    this.rowsInEditMode.delete(dataItem.rowId);
    this.editAllRows();
    this.removeFromSelectedRowList(dataItem.rowId);
    this.handleValidationOnDelete();

  }

  removeFromSelectedRowList(rowId: number) {
    const index = this.selectedRows.findIndex(item => item === rowId);
    this.selectedRows.splice(index, 1);
  }

  deleteSelectedRows(): void {
    if (this.selectedRows.length === 0) {
      return;
    }
    this.selectedRows.forEach(rowId => {
      const index = this._gridData.findIndex(dataItem => dataItem.rowId === rowId);
      this._gridData.splice(index, 1);
      this.loadGridView();
      this.rowsInEditMode.delete(rowId);
      this.editAllRows();
    });
    this.selectedRows = [];
    this.handleValidationOnDelete();
  }

  duplicateSelectedRows(): void {
    if (this.selectedRows.length === 0) {
      return;
    }
    const itemsToAppend = this.selectedRows.map(rowId => {
      const dataItem = this.mergeEditedDataWithOriginalData(
        rowId,
        this.rowsInEditMode.get(rowId)?.getRawValue()
      );
      return { ...dataItem, rowId: this.counter++, isNewRecord: true };
    });
    this._gridData.unshift(...itemsToAppend);
    this.loadGridView();
    this.selectedRows = [];
    this.editAllRows();
  }


  createFormGroup(dataItem: any): FormGroup {
    const group = this.createFormGroupFunction(dataItem) as FormGroup;
    group.addControl('rowId', new FormControl(dataItem.rowId));
    return group;
  }

  getColumnAggregate(def: GridColumnDef): string {
    const label = def.aggregate?.label ?? 'total' + def.title
    if (def.aggregate?.total) {
      return `${def.aggregate?.total} ${label}}`;
    }
    let values: any[] = [];
    if(this.readonly) {
      values = this.gridView;
    } else {
      values = this.gridView.map(dataItem => this.rowsInEditMode.get(dataItem.rowId)?.getRawValue() ?? {})
    }
    const total = values.reduce((sum, value) => {
      let fieldValue = +value[def.field];
      if (!fieldValue || isNaN(fieldValue)) {
        fieldValue = 0
      }
      return sum + fieldValue;
    }, 0);

    return `${total} ${label}`;
  }

  /**
 * Will return the lates value of the grid
 */
  getUpdatedGridData() {
    // Merging original data with data in form group value
    const data = this._gridData.filter((dataItem:any) => {
      if(this.rowsInEditMode.get(dataItem.rowId)){
       return dataItem;
      }
     }).map((dataItem:any)=>{
      return {
        ...dataItem,
      ...this.rowsInEditMode.get(dataItem.rowId)?.getRawValue(),
      }
     });
    return {
      valid: this.validate(),
      data
    }
  }
/**
 * 
 * @returns Used when whole grid merged data is Req
 */
    getWholeUpdatedGridData() {
    // Merging original data with data in form group value
    const data = this._gridData.map((dataItem:any)=>{
      return {
        ...dataItem,
        ...(this.rowsInEditMode.get(dataItem.rowId)?.getRawValue() ?? {}),
      }
     });
    return {
      valid: this.validate(),
      data
    }
  }

  validate(formGroups?: FormGroup[]): GridValidationResult {
    if(this.readonly) {
      return {
        valid: true,
        errors: []
      }
    }
    if (!formGroups) {
       formGroups = this._gridData.filter((dataItem:any) => {
        if(this.rowsInEditMode.get(dataItem.rowId)){
         return this.rowsInEditMode.get(dataItem.rowId) as FormGroup;
        }
        return;
       }).map(dataItem => this.rowsInEditMode.get(dataItem.rowId) as FormGroup);
    }

    const isValid = formGroups.every(group => group?.valid);
    return {
      valid: isValid,
      errors: isValid ? [] : this.mapFormErrors(formGroups)
    }
  }

  getEditableFieldList(): string[] {
    return this.gridColumnDefs.filter(def => def.editable).map(def => def.formControlName ?? def.field);
  }

  mapFormErrors(controls: FormGroup[]): { [key: string]: any }[] {
    const fields = this.getEditableFieldList();

    return controls.map(group => fields.reduce((result, field) => {
      return {
        ...result,
        [field]: group.get(field)?.errors
      }
    }, {}))
  }

  markFieldsAsTouched(): void {
    this._gridData.forEach(dataItem => {
      const group = this.rowsInEditMode.get(dataItem.rowId);
      group?.markAllAsTouched();
    })
  }

  rowClassCallback(context: { dataItem: any }): { [key: string]: any } {
    let errors = {};
    if(this.hightlightRowsWithError) {
      const hasError = !!this.rowsInEditMode.get(context.dataItem.rowId)?.invalid;
      errors = {
        'row-error': hasError
      }
    }
    if (this.rowClassCallbackFn){
      errors = { ...errors, ...this.rowClassCallbackFn(context) };
    }
    return errors;
  }

  columnClassCallback(dataItem: any, field: string): string | string[] | { [key: string]: any } | Set<string> {
    if (this.columnClassCallbackFn) {
      return this.columnClassCallbackFn(dataItem, field);
    }
    return '';
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.loadGridView();
    this.closeAllRows();
    this.expandAllGroupsInToggleMap();
    this.dataStateChange.emit();
    setTimeout(() => this.editRecordOnToggle(),0);
  }

  sortChange(event: SortDescriptor[]) {
    this.sort = event;
    this.loadGridView();
    this.closeAllRows();
    this.expandAllGroupsInToggleMap();
    this.dataStateChange.emit();
    setTimeout(() => this.editRecordOnToggle(),0);
  }

  editRecord(){
    const _currentGridData: any[] = this.getGridDataList();
    _currentGridData.forEach((dataItem: any,index: number) => {
      if(this.rowsInEditMode.get(dataItem.rowId)){
         const formGroups = this.rowsInEditMode.get(dataItem.rowId) as FormGroup;
         this.grid.editRow(index, formGroups);
        }}
      );
  }

  expandAllGroupsInToggleMap(): void {
    for(const [key,items] of this.toggleGrpMap.entries()) {
      this.toggleGrpMap.set(key,{
        ...items,
        expanded: true
      })
    }
  }

  collapseAllGroupsInToggleMap(): void {
    for(const [key,items] of this.toggleGrpMap.entries()) {
      this.toggleGrpMap.set(key,{
        ...items,
        expanded: false
      })
    }
  }

  groupChange(groups: GroupDescriptor[]) {
    this.groups = groups;
    this.loadGridView();
    this.closeAllRows();
    this.dataStateChange.emit();
    this.createToggleGroupMap(this._gridData);
    setTimeout(() => this.editRecordOnToggle(),0);

  }
  filterRows(filterFn: any) {
    this.closeAllRows();
    const data = this.getWholeUpdatedGridData()?.data?.filter((item) => {
      const editedValue = this.rowsInEditMode.get(item.rowId)?.value ?? {};
      return filterFn({ ...item, ...editedValue });
    });
    const group = this.groups.map((group) => ({
      ...group,
      aggregates: this.groupAggregates,
    }));
    const processedData = process(data, {
      sort: this.sort,
      filter: this.filter,
      group,
    });
    this.rowTotal = processedData.total;
    this.gridView = this._flattenGroupedGridViewData(processedData.data);
    this.expandAllGroupsInToggleMap();
    setTimeout(() => this.editRecordOnToggle(),0); 
  }

  editRecordOnToggle(): void {
    const expandedItems = this.getExpandedItems();
    expandedItems.forEach((dataItem: any,index: number) => {
      if(this.rowsInEditMode.get(dataItem.rowId)){
         const formGroups = this.rowsInEditMode.get(dataItem.rowId) as FormGroup;
        this.grid.closeRow(index);
        this.grid.editRow(index, formGroups)
        }}
      );
  }

  /**
   * Used to edit a record from parent
   */
  editSingleRow(record: any) {
    const expandedItems = this.getExpandedItems();
    const index = expandedItems.findIndex(item => item.rowId == record.rowId);
    this.grid.closeRow(index);
    const group = this.rowsInEditMode.get(record.rowId);
    if (group) {
      this.grid.editRow(index, group);
    } else {
      const group = this.createFormGroupFunction(record) as FormGroup;
      this.rowsInEditMode.set(record.rowId, group);
      this.grid.editRow(index, group);
    }
  }

  expandGroupFn(rowArgs: GroupRowArgs): void {
    const index = rowArgs.groupIndex;
    const parentGrpIndex = rowArgs.parentGroup;
    const currentIndexVal = this.toggleGrpMap.get(index);

    this.toggleGrpMap.set(index, {
        ...currentIndexVal,
        expanded: true
    });

    if (parentGrpIndex == null) {
        const keysFromMap = [...this.toggleGrpMap.keys()].filter(key => key.startsWith(index));
        keysFromMap.forEach(item => {
            const mapVal = this.toggleGrpMap.get(item);
            if (mapVal) {
                this.toggleGrpMap.set(item, {
                    ...mapVal,
                    expanded: true
                });
            }
        });
    }

    this.closeAllRows();
    setTimeout(() => {
    this.editRecordOnToggle();
    },0)
}

public collapseGroupFn(rowArgs: GroupRowArgs): void {
    const index = rowArgs.groupIndex;
    const parentGrpIndex = rowArgs.parentGroup;
    if (parentGrpIndex == null) {
        const keysToCollapse = [...this.toggleGrpMap.keys()].filter(key => key.startsWith(index));

        keysToCollapse.forEach(item => {
            const mapVal = this.toggleGrpMap.get(item);
            if (mapVal) {
                this.toggleGrpMap.set(item, {
                    ...mapVal,
                    expanded: false
                });
            }
        });
    }

    const currentIndexVal = this.toggleGrpMap.get(index);
    if (currentIndexVal) {
        this.toggleGrpMap.set(index, {
            ...currentIndexVal,
            expanded: false
        });
    }
    this.closeAllRows();
    setTimeout(() => {
        this.editRecordOnToggle();
      }, 0);
}

expandAll() {
  this.expandAllGroupsInToggleMap();
  this.groupDirective.expandAll();
}

private getExpandedItems(): any[] {
  const keys = [...this.toggleGrpMap.keys()];
  const parentKeys = new Set<string>();
  
  for (let i = 0; i < keys.length - 1; i++) {
    if (keys[i + 1].startsWith(keys[i])) {
      parentKeys.add(keys[i]);
    }
  }
  
  // Filter final keys which are not parents
  const finalKeys = keys.filter(key => !parentKeys.has(key));

  // Collect expanded node items directly
  const expandedArr = finalKeys
    .map(key => this.toggleGrpMap.get(key))
    .filter(val => val?.expanded)
    .map(val => val.node);

    let expandedItems = expandedArr.flatMap(node => this.flattenItemsWithRowIds(node.items));
    const currentGridRowIds = this.gridView.map(item => item.rowId);
    expandedItems = expandedItems.filter(item => currentGridRowIds.includes(item.rowId));
    expandedItems.sort((item1,item2) => currentGridRowIds.indexOf(item1.rowId) - currentGridRowIds.indexOf(item2.rowId));
  return expandedItems
}


private flattenItemsWithRowIds(items: any): any[] {
    let flatList:any[] = [];
    items.forEach((item: any) => {
        if (item.rowId !== undefined) {
            flatList.push(item);
        }
        if (item.items && Array.isArray(item.items)) {
            flatList = flatList.concat(this.flattenItemsWithRowIds(item.items));
        }
    });

    return flatList;
}

public getToggleGrpMap() {
  return this.toggleGrpMap;
}


  mergeEditedDataWithOriginalData(rowId: number, editValue: any): any {
    const originalRowData = this._gridData.find(item => item.rowId === rowId);
    if(!originalRowData) {
      return editValue;
    }
    return {
      ...originalRowData,
      ...editValue
    }
  }

  getColumnValue(dataItem: any, field: string): string {
    const fieldArr = field.split('.');
    return fieldArr.reduce((result, key) => {
      return result[key]
    }, { ...dataItem });
  }

  handleValidationOnDelete(): void {
    const groups = this._gridData.map(item => this.rowsInEditMode.get(item.rowId) as FormGroup);
    const payload = {
      validation: this.validate(groups)
    }
    this.valueChange.emit(payload);
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.classList.contains('k-column-title') ||
      element.hasAttribute('show-tooltip')) &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  isColumnFilterable(columnDef: GridColumnDef): boolean {
    if(columnDef.filterable === false) {
      return false;
    }

    return !columnDef.editable;
  }

  isColumnSortable(columnDef: GridColumnDef): boolean {
    if(columnDef.sortable === false) {
      return false;
    }

    return !columnDef.editable;
  }

  scrollToRow(rowIndex: number,column:number = 0) {
    this.grid.scrollTo({row: rowIndex,column : column})
  }

  public trackByIndex(index: number, item: GridItem): any {
    return index;
  }

  getGroupByRowId(rowId: number) {
    return this.rowsInEditMode.get(rowId);
  }

  calculateGroupAggregateForEditableColumn(group: any, columnDef: GridColumnDef): number {
    let items = [];
    for (let [key,value] of this.toggleGrpMap.entries()) {
        if(value.displayVal == group.value) {
          items = value.node?.items;
          break;
        }
    }

    let flattedItems = this.flattenGridDataArray(items);
    const rowIdsExistingViewData = this.gridView.map(item => item.rowId);
    flattedItems = flattedItems.filter(item => rowIdsExistingViewData.includes(item.rowId));
    const val = this.getAggregateForEditableColumn(flattedItems, columnDef);
    return val ? this.getFormattedValue(val,columnDef) : 0;
  }
  

  hasAggregate(field: string) {
    return this.groups.some(group => {
      return group.field === field && group.aggregates && group.aggregates.length > 0;
    })
  }

  getAggregateForColumn(columnDef: GridColumnDef, column: any) {
    const items = this.getGridDataList();
    let aggregatedVal;
    if(!column.editable || this.readonly) {
      aggregatedVal =  items.reduce((result: number, item: any) => {
        return result += +this.getColumnValue(item, columnDef.field);
      }, 0);
    } else {
      aggregatedVal = this.getAggregateForEditableColumn(items, columnDef);
    }
    return `${columnDef?.aggregate?.label} ${this.getFormattedValue(aggregatedVal,columnDef)}`
  }

  getAggregateForEditableColumn(items: any[], columnDef: GridColumnDef) {
    return items.reduce((result: number, item: any) => {
      const formGroup = this.rowsInEditMode.get(item.rowId);
      if(formGroup) {
        const formControlName = columnDef.formControlName ?? columnDef.field;
        if(formGroup.get(formControlName)?.value) {
          result += +formGroup.get(formControlName)?.value;
        }
      }else{
        result += +item[columnDef.field];
       }
      return result;
    }, 0)
  }

  private _flattenGroupedGridViewData(gridViewData: any[]): any[] {
    const result = []
    for(let item of gridViewData) {
      if(item.items) {
        result.push(...this._flattenGroupedGridViewData(item.items));
      } else {
        result.push(item);
      }
    }

    return result;
  }

  getAggregateValue(field: string, columnDef: GridColumnDef[]) {
    const column = columnDef?.find(column => column.field === field);
    const formControlName = column?.formControlName;
    const data = this._flattenGroupedGridViewData(this.gridView);
    const total = data.reduce((tot, item) => {
      const formGroup = this.rowsInEditMode.get(item.rowId);
      let value = 0;
      if (formGroup && formControlName) {
        value = +formGroup.get(formControlName)?.value;
      } else {
        value = item[field];
      }
      return tot + value;
    }, 0);

    if (column?.numberFormat?.required) {
      return numberFormatter(total, column.numberFormat?.locale);
    }
    return total;
  }

  getRowCount() {
    return this.rowTotal;
  }

  getFormattedValueForFields(dataItem: any, columnDef: any) {
    if (columnDef.numberFormat?.required) {
      return numberFormatter(
        dataItem[columnDef.field],
        columnDef.numberFormat?.locale
      );
    }
    return dataItem[columnDef.field];
  }

  getFormattedValue(val: any, aggregateDef: any) {
    if (aggregateDef?.numberFormat?.required) {
      return numberFormatter(val,aggregateDef.numberFormat?.locale);
    }
    return val;
  }

  isValidRow(rowId: number): boolean {
    const group = this.rowsInEditMode.get(rowId);
    if(!group) return true;
    return group.valid;
  }

  onSelectionChange(): void {
    const selectedDataItems = this._getSelectedDataItems();
    this.selectionChange.emit(selectedDataItems);
  }

  private _getSelectedDataItems(): any[] {
    return this._gridData.filter((dataItem) =>
      this.selectedRows.includes(dataItem.rowId)
    );
  }

  selectAllRows(fn: any = ""): void {
    if(fn) {
      const data = fn(this._gridData)?.map((item: any) => item.rowId);
      this.selectedRows = data
    }
    else {
      this.selectedRows = this._gridData.map((item) => item.rowId);
    }
    this.onSelectionChange();
  }

  clearSelectedRows(): void {
    this.selectedRows = [];
    this.onSelectionChange();
  }
  
  private _resetSelection(): void {
    this.selectedRows = [];
    this.onSelectionChange();
  }

  resetSelection(): void {
    this._resetSelection();
  }

  addSelection(data: any) {
    this.selectedRows = [...this.selectedRows,...data.map((item: any) => item.rowId)];
    this.onSelectionChange();
  }
  removeSelection(data: any) {
    const filterIds: any[] = data.map((item: any) => item.rowId);
    this.selectedRows = this.selectedRows.filter((item: any) => !filterIds.includes(item));
    this.onSelectionChange();
  }

  getGroupedRecords() {
    return groupBy(this._gridData, this.groups)
  }

  exportAsExcel(): void {
    this.grid.saveAsExcel();
  }

  exportAsPDF(fileName: string = "export.pdf"): void {
    if(this._gridData?.length > 100) {
      this._dialogService.openDialog({
        title: EXPORT_ERROR_TITLE,
        content: PDF_EXPORT_DISABLED_CONTENT,
        confirmAction: { text: "OK", value: "yes" },
      },ConfirmDialogComponent);
      return;
    }
    if (this.autoAdjustPdfWidth) {
      const width =
        this.grid.columns.reduce((result, column) => result + column.width, 0) +
        50;
      this.pdfOptions = {
        ...this.pdfOptions,
        paperSize: [`${width}pt`, "800pt"],
      };
    }
    setTimeout(async () => {
      const group = await this.grid.drawPDF();
      const result = await KendoExportAsPDF(group);
      saveAs(result, fileName);
    }, 0);
  }

  allDataForExcelExport(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this._gridData, {
        group: this.groups,
        sort: this.sort,
        filter: this.filter,
      }).data.map((item, index) => {
        return {
          ...item,
          [GRID_ROW_NUMBER_FIELD]: index + 1,
        };
      }),
      group: this.groups,
    };
    return result;
  }

  ngOnDestroy() {
    if(this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}